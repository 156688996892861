<template>
  <div class="content block-el">
    <template v-if="fetched">
      <PageHeader :title="$t('adm.customer.edit-prod')">
        <template v-slot:actions>
          <li>
            <a v-if="!attaching" @click="update" href="javascript:void(0)" class="btn btn-success btn-floating">
              <i class="fas fa-save text-white font-size-22 v-align-text-bottom"></i>
            </a>
            <a v-else href="javascript:void(0)" class="btn btn-success btn-floating" disabled>
              <span class="qt-loader qt-loader-mini qt-loader-right"> </span>
            </a>
          </li>
        </template>
      </PageHeader>
      <div class="page-content container-fluid">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col-4">
              <div class="card">
                <div class="card-header">{{ $t('generic-str.menu.general') }}</div>
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-12">
                      <label class="control-label text-left">Short Codes</label>
                      <input class="form-control" v-model="form.name" disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="card">
                <div class="card-header">{{ $tc('adm.customer.assign.price', 2) }}</div>
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-12">
                      <label for="name">Claro</label>
                      <input type="text" class="form-control" v-model="form.data.values.claro" v-money="money" />
                    </div>
                    <div class="form-group col-12">
                      <label for="name">Nextel</label>
                      <input type="text" class="form-control" v-model="form.data.values.nextel" v-money="money" />
                    </div>
                    <div class="form-group col-12">
                      <label for="name">Oi</label>
                      <input type="text" class="form-control" v-model="form.data.values.oi" v-money="money" />
                    </div>
                    <div class="form-group col-12">
                      <label for="name">Tim</label>
                      <input type="text" class="form-control" v-model="form.data.values.tim" v-money="money" />
                    </div>
                    <div class="form-group col-12">
                      <label for="name">Vivo</label>
                      <input type="text" class="form-control" v-model="form.data.values.vivo" v-money="money" />
                    </div>
                    <div class="form-group col-12">
                      <label for="name">{{ $tc('generic-str.other-a', 2) }}</label>
                      <input type="text" class="form-control" v-model="form.data.values.other" v-money="money" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </template>
    <!-- <div v-else class="relative qt-block-ui" style="padding: 120px" /> -->
    <div v-else class="static qt-block-ui" style="padding: 120px" />
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import CustomerService from '@/services/customer.service';
import SmsService from '@/services/admin/sms.service';
import { mask } from 'vue-the-mask';

export default {
  name: 'ShowProduct',
  components: {
    PageHeader,
  },
  directives: {
    Tab,
    mask,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 5,
        masked: false,
      },
      product: {},
      products: [],
      form: {
        billing: 'monthly',
        currency: 'brl',
        credits: 0,
        product: {},
        values: {
          claro: '0.1000',
          nextel: '0.1000',
          oi: '0.1000',
          tim: '0.1000',
          vivo: '0.1000',
          other: '0.1000',
        },
      },
      attaching: false,
      pages: 1,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.fetched = false;
      CustomerService.getShortCode(
        this.$route.params.customerId,
        this.$route.params.shortCodeId,
      ).then(
        (shortCode) => {
          this.form = shortCode;
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetchShortCodes() {
      SmsService.getShortCodes().then(
        (shortCodes) => {
          this.shortCodes = shortCodes;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeProduct(product) {
      this.form.data.values = {};
      this.product = product;
    },
    update() {
      this.attaching = true;
      CustomerService.updateShortCode(
        this.$route.params.customerId,
        this.$route.params.shortCodeId,
        this.form.data,
      )
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('adm.customer.prod-updated'),
              type: 'success',
            });
            this.$router.push(
              `/admin/customers/${this.$route.params.customerId}`,
            );
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.attaching = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-cep {
  right: 40px;
  top: 40px;
  position: absolute;
  padding: initial;
}

.select-container {
  display: block;
}
</style>
